var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spinner" }, [
    _c("div", { staticClass: "bounce1" }),
    _c("div", { staticClass: "bounce2" }),
    _c("div", { staticClass: "bounce3" }),
    _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }